@media screen {
    table.table th, table.table td {
        padding: 5px 3px;
        vertical-align: middle;
    }
}

.dataTables_wrapper {
    max-width: 700px;
    margin: auto;
}

.statistics_wrapper .dataTables_wrapper {
    max-width: 95%;
    margin: auto;
}

.admin-wrapper .dataTables_wrapper {
    max-width: 100% !important;
}

.clubMembersDataTable td,
.clubMembersDataTable th {
    text-align: left;
}

.clubsDataTable td:nth-of-type(1),
.clubsDataTable th:nth-of-type(1) {
    text-align: left;
}

.teamsDataTable td:nth-of-type(2),
.teamsDataTable th:nth-of-type(2) {
    text-align: left;
}

.teamsDataTable-men-master-league tbody tr:nth-last-of-type(2) {
    background-color: rgba(255, 0, 0, 0.2) !important;
}

.teamsDataTable-men-master-league tbody tr:nth-last-of-type(1) {
    background-color: rgba(255, 0, 0, 0.4) !important;
}

.teamsDataTable-divide-second-round-groups tbody tr:nth-child(6) {
    border-bottom: solid 2px;
}

.teamsDataTable-men-premier-league tbody tr:nth-of-type(1) {
    background-color: rgba(34, 139, 34, 0.4) !important;
}

.teamsDataTable-men-premier-league tbody tr:nth-of-type(2) {
    background-color: rgba(34, 139, 34, 0.2) !important;
}

.matchesDataTable td:nth-of-type(n + 1):nth-of-type(-n + 4),
.matchesDataTable th:nth-of-type(n + 1):nth-of-type(-n + 4) {
    text-align: left;
}

.matchDayMatchesDataTable td:nth-of-type(n + 1):nth-of-type(-n + 4),
.matchDayMatchesDataTable th:nth-of-type(n + 1):nth-of-type(-n + 4) {
    text-align: left;
}

.allMatchesDataTable td:nth-of-type(n + 1):nth-of-type(-n + 5),
.allMatchesDataTable th:nth-of-type(n + 1):nth-of-type(-n + 5) {
    text-align: left;
}

.futureMatchesDataTable td,
.futureMatchesDataTable th {
    text-align: left;
}

.seasonsDataTable td,
.seasonsDataTable th {
    text-align: left;
}

.actionableTeamDataTable td:nth-of-type(n + 1):nth-of-type(-n + 2),
.actionableTeamDataTable th:nth-of-type(n + 1):nth-of-type(-n + 2) {
    text-align: left;
}

.leaguesDataTable td:nth-of-type(n + 1):nth-of-type(-n + 2),
.leaguesDataTable th:nth-of-type(n + 1):nth-of-type(-n + 2) {
    text-align: left;
}

.usersDataTable td:nth-of-type(n + 1):nth-of-type(-n + 3),
.usersDataTable th:nth-of-type(n + 1):nth-of-type(-n + 3) {
    text-align: left;
}

.playerStatisticsDetailDataTable td:nth-of-type(n + 1):nth-of-type(-n + 2),
.playerStatisticsDetailDataTable th:nth-of-type(n + 1):nth-of-type(-n + 2) {
    text-align: left;
}

.playerStatisticsDataTable td:nth-of-type(n + 1):nth-of-type(-n + 3),
.playerStatisticsDataTable th:nth-of-type(n + 1):nth-of-type(-n + 3) {
    text-align: left;
}

.playerStatisticsDataTable td:nth-of-type(1),
.playerStatisticsDataTable th:nth-of-type(1),
.playerStatisticsDataTable td:nth-of-type(2),
.playerStatisticsDataTable th:nth-of-type(2),
.playerStatisticsDataTable td:nth-of-type(3),
.playerStatisticsDataTable th:nth-of-type(3) {
    width: 10%;
}


.teamStatisticsDataTable td:nth-of-type(2),
.teamStatisticsDataTable th:nth-of-type(2) {
    text-align: left;
}


.topPlayersDataTable td:nth-of-type(1),
.topPlayersDataTable th:nth-of-type(1),
.topPlayersDataTable td:nth-of-type(3),
.topPlayersDataTable th:nth-of-type(3) {
    text-align: left;
    width: 45%;
}
