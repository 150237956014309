.wrapper {
    display: flex;
}

.popup-content {
    height: 80% !important;
    overflow-y: auto;
}

.close-modal {
    position: absolute;
    right: 10px;
    top: -50px;
    font-size: 32px;
    background: #ffffff;
    border: none;
}

.print-button-wrapper {
    text-align: left;
    margin-left: 1vw;
}
#print-button {
    line-height: 1;
}

@media (max-width: 1400px) {
    .popup-content {
        width: 95% !important;
    }
}

@media (min-width: 1401px) {
    .popup-content {
        width: 80% !important;
    }
}
