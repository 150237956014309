.table_wrapper {
    display: flex;
    width: 75%;
    margin: auto;
}

.match_content {
    width: 40%;
    margin: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.add_home_player {
    margin-right: 3%;
    flex: 0 0 48%;
}

.home_player .content {
    margin-right: 10px;
}

.add_guest_player {
    flex: 0 0 48%;
}

.add_guest_player .content {
    margin-left: 10px;
}

.react-datepicker-wrapper {
    width: 100%;
}

input, select {
    margin: 0;
    font-family: inherit;
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.no_bottom_margin {
    margin-bottom: 0 !important;
}

.merged_tables {
    margin: 0 !important;
}