.nav_tabs a {
    background-color: #ffffff;
    margin: 0;
    border-radius: 0 !important;
    border-bottom: 0 !important;
    color: #333333;
}

.nav_tabs {
    margin-bottom: 5px !important;
    border-bottom: 0 !important;
    align-items: center;
    justify-content: center;
}

.nav_tabs_clickable a {
    font-weight: 900;
    color: #2196f3;
}

.nav_tabs a:hover {
    background-color: #2196f3;
    color: white;
}

.nav_tabs a:active {
    background-color: #2196f3;
}

.nav-link.active {
    background-color: #2196f3 !important;
    color: white !important;
}

@media only screen and (max-width: 1400px) {
    #select_season {
        margin: auto auto 10px;
        width: 60%;
    }
}

@media only screen and (min-width: 1401px) {
    #select_season {
        position: absolute;
        right: 150px;
    }
}

.calendar_btn {
    background-color: #2196f3 !important;
    color: white !important;
    border-radius: 0 !important;
    border-bottom: 0 !important;
}
@media only screen and (max-width: 768px) {
    .statistics-tabs {
        justify-content: center;
    }

    .statistics-tabs .nav-item, .average-tabs .nav-item {
        width: 60%;
    }
}