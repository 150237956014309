.failure-toast {
    color: #dc3545;
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth > div > div.react-datepicker__input-container,
.customDatePickerWidth > div > div.react-datepicker__input-container input {
    width: 100%;
}

.popup-form {
    max-width: 98%;
}
