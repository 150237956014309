@media (max-width: 767px) {
    .tablet-desktop-content {
        display: none;
    }
}

@media (min-width: 768px) {
    .mobile-content {
        display: none;
    }
}
