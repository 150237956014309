.wrapper {
    display: flex;
}

.home_player {
    margin-left: 1% !important;
    flex: 0 0 49% !important;
}

.home_player .content {
    margin-right: 10px !important;
}

.guest_player {
    margin-right: 1% !important;
    flex: 0 0 48% !important;
}

.guest_player .content {
    margin-left: 10px !important;
}

.home_title {
    float: right;
    margin-right: 125px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.guest_title {
    float: left;
    margin-left: 125px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.home_player > div > table > tbody > th,
.guest_player > div > table > tbody > th,
.home_player > div > table > tbody > td,
.guest_player > div > table > tbody > td {
    padding-top: 0;
    padding-bottom: 0;
    border-top: 0;
}

@media screen {
    table.table th, table.table td {
        padding: 5px 3px;
    }
    table > thead > tr, table > thead > tr {
        line-height: 16px;
        font-size: 12px;
    }
    table > tbody > tr, table > tbody > tr {
        line-height: 16px;
        font-size: 12px;
    }
}

.home_player > div > table > thead > tr > th, .guest_player > div > table > thead > tr > th {
    font-weight: bold;
}

.home_player table.table th,
.guest_player table.table th,
.home_player table.table td,
.guest_player table.table td {
    padding: 2px 5px !important;
}