@media screen {
    .to-print {
        display: none;
    }

    .print-button {
        display: block;
    }
}

@media print {
    .to-print {
        display: block;
        font-size: 30px;
    }

    .not-to-print {
        display: none;
    }

    th {
        color: black
    }

    .print-style {
        font-size: 20px;
    }

}
