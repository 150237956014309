#header-nav-bar {
    z-index: 1;
}

#full-page-invisible-layer {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 0;
}